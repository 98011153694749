import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (
  <div>
    <Link to={post.frontmatter.slug}>
    <b>{post.frontmatter.category}:</b> {post.frontmatter.title} ({post.frontmatter.date})
    </Link>
  </div>
)

export default PostLink